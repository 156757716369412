<script>
import ApiModeVuetable from '@/src/components/ApiModeVuetable.vue'
import Breadcrumb from '@/src/components/Breadcrumb.vue'
import PageHeader from '@/src/components/PageHeader.vue'
import AttachmentsService from '@src/services/AttachmentsService.js'
import Layout from '@layouts/main'
import FileModal from '@views/files/fileModal.vue'
import FileFilterModal from '@views/files/fileFilterModal'
import GridFormModal from '@src/components/GridFormModal.vue'
import FilterBar from '@components/FilterBar'
import validationMixin from '@src/mixins/validation'
import pagination from '@src/mixins/pagination'
import swalFeedbackVue from '@src/mixins/swalFeedback.vue'
import filterVuetable from '@src/mixins/filterVuetable'
import fileFields from '@src/fields/fileFields.vue'
import userChildrenFields from '@/src/fields/childrens/userChildrenFields.vue'
import AddLabelButton from '@/src/components/AddLabelButton.vue'
import FilterLabelButton from '@/src/components/FilterLabelButton.vue'
import MoreTableButton from '@/src/components/MoreTableButton.vue'
import CountTableButton from '@/src/components/CountTableButton.vue'
import { debounce } from 'lodash'
import moment from 'moment';

const i18nCommon = 'COMMON'
const i18nKey = 'FILES'

export default {
    components: {
        ApiModeVuetable,
        Breadcrumb,
        PageHeader,
        Layout,
        FileModal,
        FileFilterModal,
        GridFormModal,
        FilterBar,
        AddLabelButton,
        FilterLabelButton,
        MoreTableButton,
        CountTableButton,
    },
    mixins: [
        validationMixin,
        pagination,
        swalFeedbackVue,
        filterVuetable,
        fileFields,
        userChildrenFields,
    ],
    data() {
        return {
            sortOrder: [
                {
                    field: 'name',
                    direction: 'asc',
                },
            ],
            filters: {
                type: null,
                regionals: [],
                teams: [],
                users: [],
                roles: [],
            },
            i18nCommon: i18nCommon,
            i18nKey: i18nKey,
            modalHasResponseError: false,
            modalIsEdit: false,
            modal: {
                id: 0,
                name: null,
                name_menu: null,
                type: null,
                content: null,
                file_id: 0,
                file: null,
                url: null,
                start_at: null,
                end_at: null,
                point_of_sale_regionals: [],
                teams: [],
                users: [],
                roles: [],
            },
            data: [],
            perPage: 10,
            filterText: '',
            additionalParameters: {
                with: [
                    'file',
                    'pointOfSaleRegionals',
                    'teams',
                    'users',
                    'roles',
                ],
            },
            submitLoading: false,
            nameFields: [
                {
                    key: 'name',
                    label: this.getI18n(i18nCommon, 'name'),
                    sortable: true,
                },
            ],
            roleFields: [
                {
                    key: 'alias',
                    label: this.getI18n(i18nCommon, 'name'),
                    sortable: true,
                },
            ],
        }
    },
    computed: {
        items() {
            return [
                {
                    text: 'Home',
                    href: '/',
                },
                {
                    text: this.getI18nModified({
                        prefix: 'USERS',
                        suffix: 'TITLES.collaborator',
                        modifier: 2,
                    }),
                    active: true,
                },
                {
                    text: this.getI18nModified({
                        prefix: i18nCommon,
                        suffix: 'file',
                        modifier: 2,
                    }),
                    active: true,
                },
            ]
        },
        i18nNewFile() {
            return this.getI18n(i18nKey, 'BUTTONS.new_file')
        },
    },
    watch: {
        filterText: debounce(function (searchText) {
            this.additionalParameters = {
                with: [
                    'file',
                    'pointOfSaleRegionals',
                    'teams',
                    'users',
                    'roles',
                ],
                search: '',
            }
            if (searchText && searchText.length > 0) {
                this.additionalParameters.search = `name:${searchText};name_menu:${searchText}`
            }
            this.refreshTable()
        }, 1000),
    },
    methods: {
        createAdditionalParameters() {
            return this.formatParameters({
                with: [
                    'file',
                    'pointOfSaleRegionals',
                    'teams',
                    'users',
                    'roles',
                ],
                point_of_sale_regional_id: this.filters.regionals.map(item => item.id),
                team_id: this.filters.teams.map(item => item.id),
                user_id: this.filters.users.map(item => item.id),
                role_id: this.filters.roles.map(item => item.id),
            })
        },

        reloadTable() {
            this.$refs.vuetable.reload()
        },

        refreshTable() {
            this.$refs.vuetable.refresh()
        },

        fetch(url, params) {
            return AttachmentsService.fetchVuetable(url, params)
        },

        i18nFile(modifier) {
            return this.getI18nModified({
                prefix: 'FILES.TITLES',
                suffix: 'file',
                modifier: modifier,
            })
        },

        filter(appliedFilters) {
            this.filters = appliedFilters
            this.additionalParameters = this.createAdditionalParameters()
            this.refreshTable()
        },

        showFilterModal() {
            this.$nextTick(() =>
                this.$refs.fileFilterModal.showModal(this.filters)
            )
        },

        cleanModal() {
            this.$nextTick(() => this.$refs.fileModal.cleanModal())
        },

        replaceModalData(data) {
            Object.assign(this.modal, data)

            Object.assign(this.modal, {
                point_of_sale_regionals: [],
                teams: [],
                users: [],
                roles: [],
            })

            if (this.modal.start_at) {
                this.modal.start_at = moment(this.modal.start_at, 'YYYY-MM-DD').toDate();
            }

            if (this.modal.end_at) {
                this.modal.end_at = moment(this.modal.end_at, 'YYYY-MM-DD').toDate();
            }

            Object.assign(this.modal.point_of_sale_regionals, data.point_of_sale_regionals)
            Object.assign(this.modal.teams, data.teams)
            Object.assign(this.modal.users, data.users)
            Object.assign(this.modal.roles, data.roles)
        },

        showModal() {
            this.$nextTick(() => this.$refs.fileModal.showModal())
        },

        showImportModal() {
            this.$nextTick(() => this.$refs.importModal.showModal())
        },

        openModalToCreate() {
            this.modalIsEdit = true
            this.cleanModal()
            this.showModal()
        },

        openModalToEdit(data) {
            this.modalIsEdit = false
            this.replaceModalData(data)
            this.showModal()
        },

        openModalRegionals(data) {
            this.replaceModalData(data)
            this.$nextTick(() => this.$refs.gridRegionalsModal.showModal())
        },

        openModalTeams(data) {
            this.replaceModalData(data)
            this.$nextTick(() => this.$refs.gridTeamsModal.showModal())
        },

        openModalUsers(data) {
            this.replaceModalData(data)
            this.$nextTick(() => this.$refs.gridUsersModal.showModal())
        },

        openModalRoles(data) {
            this.replaceModalData(data)
            this.$nextTick(() => this.$refs.gridRolesModal.showModal())
        },
    },
}
</script>

<template>
    <Layout>
        <div class="col-12">
            <Breadcrumb :items="items" />
            <div class="row mt-3 mb-3">
                <div class="col-sm-6 form-inline">
                    <filter-bar v-model="filterText" />
                    <filter-label-button @onClick="showFilterModal" />
                </div>
                <div class="col-sm-6">
                    <div class="text-sm-right">
                        <add-label-button
                            v-if="$can('CREATE', 'ATTACHMENT')"
                            :title="i18nNewFile"
                            @onClick="openModalToCreate"
                        />
                    </div>
                </div>
            </div>
            <div class="card card-pdv">
                <PageHeader :title="i18nFile(2)" />
                <div class="card-body">
                    <api-mode-vuetable
                        ref="vuetable"
                        :fetch-data="fetch"
                        :fields="fileFields"
                        :per-page="perPage"
                        :additional-parameters="additionalParameters"
                        :sort-order="sortOrder">
                        <template slot="point_of_sale_regionals" slot-scope="props">
                            <count-table-button
                                :value="props.rowData.point_of_sale_regionals.length"
                                @onClick="openModalRegionals(props.rowData)"
                            />
                        </template>
                        <template slot="teams" slot-scope="props">
                            <count-table-button
                                :value="props.rowData.teams.length"
                                @onClick="openModalTeams(props.rowData)"
                            />
                        </template>
                        <template slot="users" slot-scope="props">
                            <count-table-button
                                :value="props.rowData.users.length"
                                @onClick="openModalUsers(props.rowData)"
                            />
                        </template>
                        <template slot="roles" slot-scope="props">
                            <count-table-button
                                :value="props.rowData.roles.length"
                                @onClick="openModalRoles(props.rowData)"
                            />
                        </template>
                        <template slot="file" slot-scope="props">
                            <a v-if="props.rowData.file && props.rowData.file.url"
                                :href="props.rowData.file.url"
                                target="_blank">
                                <i class="fe-file"/>
                            </a>
                            <a v-else-if="props.rowData.url"
                                :href="props.rowData.url"
                                target="_blank">
                                <i class="fe-link"/>
                            </a>
                            <i v-else class="fe-slash" />
                        </template>
                        <template slot="actions" slot-scope="props">
                            <div class="table-button-container">
                                <more-table-button
                                    @onClick="openModalToEdit(props.rowData)"
                                />
                            </div>
                        </template>
                    </api-mode-vuetable>
                </div>
            </div>
        </div>

        <file-modal
            ref="fileModal"
            :modal-is-edit="modalIsEdit"
            :modal="modal"
            @refresh="reloadTable"
        />

        <file-filter-modal ref="fileFilterModal" :handle-submit="filter" />

        <grid-form-modal
            ref="gridRegionalsModal"
            :modal="modal"
            :items="modal.point_of_sale_regionals"
            :fields="nameFields"
            :title="
                getI18nModified({
                    prefix: 'POS',
                    suffix: 'TITLES.regional',
                    modifier: 2,
                })
            "
        />

        <grid-form-modal
            ref="gridTeamsModal"
            :modal="modal"
            :items="modal.teams"
            :fields="nameFields"
            :title="
                getI18nModified({
                    prefix: 'TEAMS',
                    suffix: 'TITLES.team',
                    modifier: 2,
                })
            "
        />

        <grid-form-modal
            ref="gridUsersModal"
            :modal="modal"
            :items="modal.users"
            :fields="userChildrenFields"
            :title="
                getI18nModified({
                    prefix: 'USERS',
                    suffix: 'TITLES.user',
                    modifier: 2,
                })
            "
        />

        <grid-form-modal
            ref="gridRolesModal"
            :modal="modal"
            :items="modal.roles"
            :fields="roleFields"
            :title="
                getI18nModified({
                    prefix: 'SECURITY',
                    suffix: 'TITLES.role',
                    modifier: 2,
                })
            "
        />
    </Layout>
</template>
