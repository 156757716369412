<script>
const i18nKey = 'FILES'

export default {
    data() {
        return {
            fileTypes: [
                {
                    text: this.fileTypeToText('text'),
                    value: 'text',
                },
                {
                    text: this.fileTypeToText('image'),
                    value: 'image',
                },
                {
                    text: this.fileTypeToText('pdf'),
                    value: 'pdf',
                },
                {
                    text: this.fileTypeToText('url'),
                    value: 'url',
                },
                {
                    text: this.fileTypeToText('video_url'),
                    value: 'video_url',
                },
            ],
        }
    },

    methods: {
        fileTypeToText(value) {
            return this.getI18n(i18nKey, `TYPES.${value}`)
        },
    },
}
</script>
