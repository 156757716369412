<template>
    <form-modal
        ref="formModal"
        :handle-open="resetValidation"
        :title="i18nTitleModal"
        :in-editing="isEdit"
        size="xl"
        :handle-close="handleCancel"
    >
        <template v-slot:form>
            <form @submit.prevent="handleOk">
                <confirmation-form-modal
                    ref="confirmationFormModal"
                    :title="i18nFile"
                    :operation="isEdit ? modalOperation : 'deleted'"
                />
                <feedback-modal
                    ref="feedbackModal"
                    :title="i18nFile"
                />
                <b-row v-if="!isEdit" class="mb-2">
                    <div class="col-sm-6 form-inline">
                    </div>
                    <div class="col-sm-6">
                        <div class="text-sm-right">
                            <edit-label-button
                                v-if="$can('UPDATE', 'ATTACHMENT')"
                                :title="getI18n('FILES', 'TITLES.file')"
                                :busy="editLoading"
                                :disabled="deleteLoading"
                                @onClick="handleEdit"
                            />
                           <delete-label-button
                                v-if="$can('DELETE', 'ATTACHMENT')"
                                :title="getI18n('FILES', 'TITLES.file')"
                                :busy="deleteLoading"
                                :disabled="editLoading"
                                @onClick="handleDelete"
                            />
                        </div>
                    </div>
                </b-row>
                <b-tabs
                    id="users-tabs"
                    v-model="tabIndex"
                    class="tabs-pdv"
                    @activate-tab="tabActivated">
                    <b-tab :title="i18nInformation">
                        <b-form-row>
                            <b-col md="6">
                                <b-form-group
                                    class="required label-pdv"
                                    :label="i18nName"
                                    label-for="modal-name"
                                    :invalid-feedback="getI18n('ERROR_CODES.invalid_name')"
                                >
                                    <b-form-input
                                        id="modal-name"
                                        v-model.trim="$v.fileModal.name.$model"
                                        class="input-pdv-blue"
                                        name="name"
                                        aria-describedby="input-1-live-feedback"
                                        type="text"
                                        :disabled="!isEdit || submitLoading"
                                        :state="validateField('name', 'fileModal')"
                                        autofocus
                                        autocomplete="off"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col md="3">
                                <b-form-group
                                    class="required label-pdv"
                                    :label="i18nStartDate"
                                    label-for="modal-start-date"
                                    :invalid-feedback="getI18n('ERROR_CODES.invalid_type')"
                                >
                                    <date-picker
                                        id="modal-start-date"
                                        :child-class="validationClass($v.fileModal.start_at)"
                                        :value.sync="$v.fileModal.start_at.$model"
                                        :disabled="!isEdit || submitLoading"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col md="3">
                                <b-form-group
                                    class="label-pdv"
                                    :label="i18nEndDate"
                                    label-for="modal-end-date"
                                    :invalid-feedback="getI18n('ERROR_CODES.invalid_type')"
                                >
                                    <date-picker
                                        id="modal-start-date"
                                        :child-class="validationClass($v.fileModal.end_at)"
                                        :value.sync="$v.fileModal.end_at.$model"
                                        :disabled="!isEdit || submitLoading"
                                    />
                                </b-form-group>
                            </b-col>
                        </b-form-row>
                        <b-form-row>
                            <b-col md="3">
                                <b-form-group
                                    class="label-pdv"
                                    :label="i18nMenu"
                                    label-for="modal-name-menu"
                                >
                                    <b-form-input
                                        id="modal-name-menu"
                                        v-model.trim="$v.fileModal.name_menu.$model"
                                        class="input-pdv-blue"
                                        name="name"
                                        aria-describedby="input-1-live-feedback"
                                        type="text"
                                        :disabled="!isEdit || submitLoading"
                                        :state="validateField('name_menu', 'fileModal')"
                                        autocomplete="off"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col md="3">
                                <b-form-group
                                    class="required label-pdv"
                                    :label="i18nType"
                                    label-for="modal-type"
                                    :invalid-feedback="getI18n('ERROR_CODES.invalid_type')"
                                >
                                    <translated-multiselect
                                        id="modal-type"
                                        v-model="type"
                                        :options="fileTypes"
                                        :select-class="validationClass($v.fileModal.type)"
                                        track-by="value"
                                        label="text"
                                        :disabled="!isEdit || submitLoading || isFileCreated"
                                        @input="changeType"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col v-show="fileModal.type === 'url' || fileModal.type === 'video_url'" md="6">

                                <b-form-group
                                    class="required label-pdv"
                                    :label="i18nUrl"
                                    label-for="modal-url"
                                    :invalid-feedback="getI18n('ERROR_CODES.invalid_url')"
                                >
                                    <b-input-group>
                                        <b-form-input
                                            id="modal-name"
                                            v-model.trim="$v.fileModal.url.$model"
                                            class="input-pdv-blue"
                                            name="name"
                                            aria-describedby="input-1-live-feedback"
                                            type="text"
                                            :disabled="!isEdit || submitLoading"
                                            :state="validateField('url', 'fileModal')"
                                            autocomplete="off"
                                        />
                                        <b-input-group-append>
                                            <b-button
                                                class="btn-pdv-append-blue"
                                                variant="primary"
                                                :disabled="!$v.fileModal.url.$model"
                                                :href="$v.fileModal.url.$model"
                                                target="_blank"
                                            >
                                                <i v-if="$v.fileModal.url.$model" class="fe-link"/>
                                                <i v-else class="fe-slash" />
                                            </b-button>
                                        </b-input-group-append>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                            <b-col v-show="fileModal.type === 'image' || fileModal.type === 'pdf'" md="3">
                                <b-form-group
                                    class="required label-pdv"
                                    :label="i18nFile"
                                    label-for="modal-upload-file"
                                    :invalid-feedback="getI18n('ERROR_CODES', 'invalid_file')"
                                >
                                    <b-button
                                        v-if="isFileCreated"
                                        variant="primary"
                                        :disabled="!fileModal.file || !fileModal.file.url"
                                        :href="fileModal.file ? fileModal.file.url : ''"
                                        target="_blank"
                                    >
                                        <i v-if="fileModal.file && fileModal.file.url" class="fe-link"/>
                                        <i v-else class="fe-slash" />
                                    </b-button>
                                    <b-form-file
                                        v-else
                                        id="modal-upload-file"
                                        ref="modal-upload-file"
                                        v-model="$v.fileModal.selectedFile.$model"
                                        required
                                        type="file"
                                        accept=".jpg, .png, .jpeg, .pdf"
                                        :browse-text="getI18n('COMMON', 'browse')"
                                        :placeholder="getI18n('COMMON', 'no_file_chosen')"
                                        :state="validateField('selectedFile', 'fileModal')"
                                        :disabled="!isEdit || submitLoading"
                                        @blur="$v.fileModal.selectedFile.$touch"
                                    />
                                </b-form-group>
                            </b-col>
                        </b-form-row>
                        <b-form-row>
                            <b-col class="col-sm-12 col-md-12">
                                <b-form-group
                                    :label="i18nText"
                                    label-for="add-text-input"
                                    :class="`${fileModal.type === 'text' ? 'required' : ''} label-pdv`"
                                    :invalid-feedback="getI18n('COMMON', 'mandatory')">
                                    <vue-editor
                                        :id="'add-text-input'"
                                        v-model="$v.fileModal.content.$model"
                                        :class="`${validationClass($v.fileModal.content)} editor-pdv-blue`"
                                        :disabled="!isEdit || submitLoading"
                                    />
                                </b-form-group>
                            </b-col>
                        </b-form-row>
                    </b-tab>

                    <b-tab :title="i18nRegionals">
                        <b-form-row>
                            <b-col v-if="isEdit" class="col-sm-12 col-md-4">
                                <b-form-group
                                    v-if="isEdit"
                                    :label="i18nRegional"
                                    label-for="add-regional-input"
                                    class="label-pdv" >
                                    <multiSelectWithService
                                        :id="'add-regional-input'"
                                        ref="regionalMultiselect"
                                        v-model="regionalName"
                                        :service="'pos-regionals'"
                                        :searchable="true"
                                        :multiple="false"
                                        :disabled="submitLoading"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col :class="isEdit ? 'col-sm-12 col-md-8' : 'col-sm-12 col-md-12'">
                                <data-mode-vuetable
                                    ref="regionalsRelationVuetable"
                                    v-model="fileModal.point_of_sale_regionals"
                                    :fields="nameFields"
                                    :display-delete-button="isEdit"
                                    :disabled="submitLoading"
                                    @onClickDelete="handleRemoveRegional"
                                />
                            </b-col>
                        </b-form-row>
                    </b-tab>

                    <b-tab :title="i18nTeams">
                        <b-form-row>
                            <b-col v-if="isEdit" class="col-sm-12 col-md-4">
                                <b-form-group
                                    v-if="isEdit"
                                    :label="i18nTeam"
                                    label-for="add-team-input"
                                    class="label-pdv">
                                    <multiSelectWithService
                                        :id="'add-team-input'"
                                        ref="teamMultiselect"
                                        v-model="teamName"
                                        :service="'teams'"
                                        :searchable="true"
                                        :multiple="false"
                                        :disabled="submitLoading"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col :class="isEdit ? 'col-sm-12 col-md-8' : 'col-sm-12 col-md-12'">
                                <data-mode-vuetable
                                    ref="teamsRelationVuetable"
                                    v-model="fileModal.teams"
                                    :fields="nameFields"
                                    :display-delete-button="isEdit"
                                    :disabled="submitLoading"
                                    @onClickDelete="handleRemoveTeam"
                                />
                            </b-col>
                        </b-form-row>
                    </b-tab>

                    <b-tab :title="i18nUsers">
                        <b-form-row>
                            <b-col v-if="isEdit" class="col-sm-12 col-md-4">
                                <b-form-group
                                    v-if="isEdit"
                                    :label="i18nUser"
                                    label-for="add-user-input"
                                    class="label-pdv">
                                    <multiSelectWithService
                                        :id="'add-user-input'"
                                        ref="userMultiselect"
                                        v-model="userName"
                                        :service="'users'"
                                        :searchable="true"
                                        :multiple="false"
                                        :disabled="submitLoading"
                                        :parameters="usersParameters"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col :class="isEdit ? 'col-sm-12 col-md-8' : 'col-sm-12 col-md-12'">
                                <data-mode-vuetable
                                    ref="usersRelationVuetable"
                                    v-model="fileModal.users"
                                    :fields="userChildrenActionFields"
                                    :display-delete-button="isEdit"
                                    :disabled="submitLoading"
                                    @onClickDelete="handleRemoveUser"
                                />
                            </b-col>
                        </b-form-row>
                    </b-tab>

                    <b-tab :title="i18nRoles">
                        <b-form-row>
                            <b-col v-if="isEdit" class="col-sm-12 col-md-4">
                                <b-form-group
                                    v-if="isEdit"
                                    :label="i18nRole"
                                    label-for="add-role-input"
                                    class="label-pdv">
                                    <multiSelectWithService
                                        :id="'add-role-input'"
                                        ref="roleMultiselect"
                                        v-model="roleName"
                                        :service="'roles'"
                                        label="alias"
                                        :searchable="true"
                                        :multiple="false"
                                        :disabled="submitLoading"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col :class="isEdit ? 'col-sm-12 col-md-8' : 'col-sm-12 col-md-12'">
                                <data-mode-vuetable
                                    ref="rolesRelationVuetable"
                                    v-model="fileModal.roles"
                                    :fields="roleFields"
                                    :display-delete-button="isEdit"
                                    :disabled="submitLoading"
                                    @onClickDelete="handleRemoveRole"
                                />
                            </b-col>
                        </b-form-row>
                    </b-tab>
                </b-tabs>
            </form>
        </template>
        <template v-slot:footer>
            <div v-if="isEdit" class="d-flex justify-content-center">
                <cancel-button
                    ref="cancelButton"
                    child-class="col-sm-4 float-left"
                    :disabled="submitLoading"
                    @onClick="handleCancel"
                />
                <save-button
                    v-if="tabIndex !== 4"
                    ref="okButton"
                    child-class="col-sm-4 float-right ml-3"
                    :title="getI18n('COMMON.next')"
                    :busy="submitLoading"
                    @onClick="tabIndex++"
                />
                <save-button
                    v-else-if="isFileCreated"
                    ref="okButton"
                    child-class="col-sm-4 float-right ml-3"
                    :busy="submitLoading"
                    @onClick="handleOk"
                />
                <add-button
                    v-else
                    ref="okButton"
                    child-class="col-sm-4 float-right ml-3"
                    :busy="submitLoading"
                    @onClick="handleOk"
                />
            </div>
        </template>
    </form-modal>
</template>

<script>
import {
    required,
    requiredIf,
    minLength,
} from 'vuelidate/lib/validators'
import AttachmentsService from '@src/services/AttachmentsService.js'
import FilesService from '@src/services/FilesService.js'
import swalFeedback from '@src/mixins/swalFeedback.vue'
import validationMixin from '@src/mixins/validation.vue'
import FormModal from '@/src/components/FormModal.vue'
import ConfirmationFormModal from '@src/components/ConfirmationFormModal'
import FeedbackModal from '@src/components/FeedbackModal'
import fieldsValidation from '@src/mixins/fieldsValidation.vue'
import vuetableFormatter from '@src/mixins/vuetableFormatters'
import CancelButton from '@/src/components/CancelButton'
import AddButton from '@/src/components/AddButton'
import SaveButton from '@/src/components/SaveButton'
import EditLabelButton from '@/src/components/EditLabelButton.vue'
import DeleteLabelButton from '@/src/components/DeleteLabelButton.vue'
import translatedMultiselect from '@src/components/translatedMultiselect'
import multiSelectWithService from '@src/components/multiSelectWithService'
import DatePicker from '@/src/components/DatePicker'
import DataModeVuetable from '@/src/components/DataModeVuetable.vue'
import { VueEditor } from "vue2-editor";
import userChildrenActionFields from '@src/fields/childrens/userChildrenActionFields'
import fileTypes from '@src/mixins/fileTypes.vue'
import moment from 'moment';

const i18nKey = 'FILES'
const i18nCommon = 'COMMON'

const isFileValid = (value, model) => {
    if (!value || !value.type) {
        return true
    }

    if (model.type === 'image' && value.type.startsWith('image')) {
        return true
    }

    if (model.type === 'pdf' && value.type === 'application/pdf') {
        return true
    }

    return false
}

export default {
    components: {
        FormModal,
        ConfirmationFormModal,
        FeedbackModal,
        CancelButton,
        AddButton,
        SaveButton,
        EditLabelButton,
        DeleteLabelButton,
        translatedMultiselect,
        multiSelectWithService,
        DatePicker,
        DataModeVuetable,
        VueEditor,
    },
    mixins: [
        validationMixin,
        swalFeedback,
        fieldsValidation,
        vuetableFormatter,
        userChildrenActionFields,
        fileTypes
    ],
    props: {
        modalIsEdit: {
            required: true,
            type: Boolean,
        },
        // eslint-disable-next-line
        modal: Object,
    },
    data() {
        return {
            i18nKey,
            i18nCommon,
            fileModal: {},
            isEdit: false,
            editLoading: false,
            submitLoading: false,
            deleteLoading: false,
            tabIndex: 0,
            type: null,
            regionalName: '',
            teamName: '',
            userName: '',
            roleName: '',
            nameFields: [
                {
                    key: 'name',
                    label: this.getI18n(i18nCommon, 'name'),
                    sortable: true,
                },
                {
                    key: 'actions',
                    label: '',
                },
            ],
            roleFields: [
                {
                    key: 'alias',
                    label: this.getI18n(i18nCommon, 'name'),
                    sortable: true,
                },
                {
                    key: 'actions',
                    label: '',
                },
            ],
            usersParameters: {
                per_page: 1000,
                page: 1,
                orderBy: 'name',
                sortedBy: 'asc',
                active: true,
            },
        }
    },
    validations: {
        fileModal: {
            name: {
                required,
                minLength: minLength(2),
            },
            name_menu: {
            },
            type: {
                required,
            },
            start_at: {
                required,
                beforeEndDate(value, model) {
                    return model.end_at ? value <= model.end_at : true
                },
            },
            end_at: {
                afterStartDate(value, model) {
                    return value ? value >= model.start_at : true
                },
            },
            url: {
                required: requiredIf(function (modal) {
                    return modal.type === 'url' || modal.type === 'video_url'
                }),
            },
            content: {
                required: requiredIf(function (modal) {
                    return modal.type === 'text'
                }),
            },
            selectedFile: {
                isFileValid,
            },
        },
    },
    computed: {
        isFileCreated() {
            return this.fileModal && this.fileModal.id > 0
        },
        i18nTitleModal() {
            if (this.isFileCreated) {
                if (this.isEdit) {
                    return `${this.getI18n(i18nCommon, 'edit')} ${this.i18nFile}`
                }
                return this.fileModal.name
            }
            else {
                return this.getI18n(i18nKey, 'BUTTONS.new_file')
            }
        },
        i18nName() {
            return this.getI18n(i18nCommon, 'name')
        },
        i18nMenu() {
            return this.getI18n(i18nCommon, 'menu')
        },
        i18nType() {
            return this.getI18n(i18nCommon, 'type')
        },
        i18nUrl() {
            return this.getI18n(i18nKey, 'TYPES.url')
        },
        i18nText() {
            return this.getI18n(i18nKey, 'TYPES.text')
        },
        i18nStartDate() {
            return this.getI18n(i18nCommon, 'start_date')
        },
        i18nEndDate() {
            return this.getI18n(i18nCommon, 'end_date')
        },
        i18nFile() {
            return this.getI18nModified({
                prefix: i18nKey + '.TITLES.file',
                modifier: 1,
            })
        },
        i18nInformation() {
            return this.getI18nModified({
                prefix: 'COMMON.info',
                modifier: 2,
            })
        },
        i18nRegional() {
            return this.getI18n('POS', 'TITLES.regional')
        },
        i18nRegionals() {
            return this.getI18nModified({
                prefix: 'POS',
                suffix: 'TITLES.regional',
                modifier: 2,
            })
        },
        i18nTeam() {
            return this.getI18n('TEAMS', 'TITLES.team')
        },
        i18nTeams() {
            return this.getI18nModified({
                prefix: 'TEAMS',
                suffix: 'TITLES.team',
                modifier: 2,
            })
        },
        i18nUser() {
            return this.getI18n('USERS', 'TITLES.user')
        },
        i18nUsers() {
            return this.getI18nModified({
                prefix: 'USERS',
                suffix: 'TITLES.user',
                modifier: 2,
            })
        },
        i18nRole() {
            return this.getI18n('SECURITY', 'TITLES.role')
        },
        i18nRoles() {
            return this.getI18nModified({
                prefix: 'SECURITY',
                suffix: 'TITLES.role',
                modifier: 2,
            })
        },
        modalOperation() {
            return this.isFileCreated ? 'edited' : 'created'
        },
    },
    watch: {
        regionalName(value) {
            if (value) {
                const index = this.fileModal.point_of_sale_regionals.findIndex(find => find.id === value.id);
                if (index < 0) {
                    this.fileModal.point_of_sale_regionals.push(value)
                }
                this.regionalName = ''
                this.$refs.regionalMultiselect.$el.focus()
            }
        },
        teamName(value) {
            if (value) {
                const index = this.fileModal.teams.findIndex(find => find.id === value.id);
                if (index < 0) {
                    this.fileModal.teams.push(value)
                }
                this.teamName = ''
                this.$refs.teamMultiselect.$el.focus()
            }
        },
        userName(value) {
            if (value) {
                const index = this.fileModal.users.findIndex(find => find.id === value.id);
                if (index < 0) {
                    this.fileModal.users.push(value)
                }
                this.userName = ''
                this.$refs.userMultiselect.$el.focus()
            }
        },
        roleName(value) {
            if (value) {
                const index = this.fileModal.roles.findIndex(find => find.id === value.id);
                if (index < 0) {
                    this.fileModal.roles.push(value)
                }
                this.roleName = ''
                this.$refs.roleMultiselect.$el.focus()
            }
        },
    },
    methods: {
        tabActivated(newTabIndex, oldTabIndex, event) {
            if (this.isEdit) {
                if (oldTabIndex === 0) {
                    this.$v.fileModal.$touch()
                    if (this.$v.fileModal.$invalid) {
                        event.preventDefault()
                    }
                    if (!this.isFileCreated && !this.fileModal.selectedFile && (this.fileModal.type === 'image' || this.fileModal.type === 'pdf')) {
                        this.warningFeedback(this.i18nFile, this.getI18n('COMMON.no_file_chosen'))
                        event.preventDefault()
                    }
                }
            }
        },
        changeType(option) {
            if (option) {
                if (option.value !== 'url' && option.value !== 'video_url') {
                    this.$v.fileModal.url.$model = null
                    this.$v.fileModal.url.$touch()
                }
                if (option.value !== 'image' && option.value !== 'pdf') {
                    this.$v.fileModal.selectedFile.$model = null
                    this.$v.fileModal.selectedFile.$touch()
                }
            }

            this.$v.fileModal.type.$model = option ? option.value : null
            this.$v.fileModal.type.$touch()
        },
        showModal() {
            this.isEdit = this.modalIsEdit
            this.editLoading = false
            this.submitLoading = false
            this.deleteLoading = false
            this.tabIndex = 0
            this.fileModal = this.modal
            this.type = this.fileTypes.find(find => find.value === this.fileModal.type) ?? null
            this.$refs.formModal.show()
            this.resetValidation()
        },
        clearAllResponseError() {
            this.modalResponseErrors = {}
        },
        cleanModal() {
            this.type = null
            this.fileModal = Object.assign(this.fileModal, {
                id: 0,
                name: null,
                name_menu: null,
                type: null,
                content: null,
                file_id: 0,
                file: null,
                url: null,
                start_at: null,
                end_at: null,
                point_of_sale_regionals: [],
                teams: [],
                users: [],
                roles: [],
            })

            this.$v.fileModal.$reset()
        },
        handleEdit() {
            this.editLoading = true

            this.isEdit = true
            this.tabIndex = 0
            this.editLoading = false
        },
        handleCancel() {
            if (this.isEdit) {
                this.$refs.confirmationFormModal.showModal(true, this.handleClose)
            }
            else {
                this.handleClose(true)
            }
        },
        handleClose(close) {
            if (!close) {
                return;
            }
            this.resetValidation()
            this.$refs.formModal.hide()
        },
        resetValidation() {
            this.$nextTick(() => this.$v.$reset())
            this.clearAllResponseError()
        },
        handleOk() {
            this.$v.fileModal.$touch()
            if (!this.$v.fileModal.$invalid && !this.submitLoading) {
                this.$refs.confirmationFormModal.showModal(false, this.handleSubmit)
            }
        },
        async handleSubmit(submit) {
            if (!submit) {
                return;
            }

            this.submitLoading = true

            let saveAttachment = true

            if (this.fileModal.selectedFile) {
                const responseImg = await FilesService.uploadFile(
                    this.fileModal.selectedFile,
                    'attachment_files')
                    .then((responseImg) => responseImg)
                    .catch((error) => {
                        this.warningFeedbackApi(this.i18nFile, error.data.errors)
                        this.submitLoading = false
                    })

                const statusCodeImg = responseImg.status.toString()
                saveAttachment = statusCodeImg.charAt(0) === '2'
                if (statusCodeImg.charAt(0) === '2') {
                    this.fileModal.file_id = responseImg.data.data.id
                }
            }

            if (saveAttachment) {
                const fileInfo = {
                    id: this.fileModal.id,
                    file_id: this.fileModal.file_id && this.fileModal.file_id > 0 ? this.fileModal.file_id : null,
                    name: this.fileModal.name,
                    type: this.fileModal.type,
                    name_menu: this.fileModal.name_menu,
                    start_at: moment(this.fileModal.start_at).format('YYYY-MM-DD'),
                    end_at: this.fileModal.end_at ? moment(this.fileModal.end_at).format('YYYY-MM-DD') : null,
                    content: this.fileModal.content,
                    url: this.fileModal.url,
                    users: this.fileModal.users,
                    teams: this.fileModal.teams,
                    roles: this.fileModal.roles,
                    point_of_sale_regionals: this.fileModal.point_of_sale_regionals,
                }

                const operation = this.isFileCreated
                    ? AttachmentsService.update(fileInfo.id, fileInfo)
                    : AttachmentsService.create(fileInfo)

                const response = await operation
                    .then((response) => response)
                    .catch((errors) => errors)

                const statusCode = response.status.toString()

                if (statusCode.charAt(0) === '2') {
                    this.$emit('refresh')
                    this.resetValidation()
                    this.$refs.formModal.hide()
                    this.positiveFeedback(this.i18nFile, this.modalOperation, 0)
                }

                if (statusCode === '422') {
                    this.modalResponseErrors = response.data.errors
                    this.warningFeedbackApi(this.i18nFile, response.data.errors)
                }
            }

            this.submitLoading = false
        },
        handleDelete() {
            if (this.deleteLoading) {
                return;
            }

            this.$refs.confirmationFormModal.showModal(false, this.deleteFile)
        },
        async deleteFile(deleteRecord) {
            if (!deleteRecord) {
                return;
            }

            this.$v.fileModal.$touch()

            this.deleteLoading = true
            const response = await AttachmentsService.delete(this.fileModal.id)
                .then((response) => response)
                .catch((error) => error)

            const statusCode = response.status.toString()

            if (statusCode.charAt(0) === '2') {
                this.$emit('refresh')
                this.resetValidation()
                this.$refs.formModal.hide()
                this.positiveFeedback(this.i18nFile, 'deleted', 3)
            } else if (statusCode === '417') {
                this.$refs.feedbackModal.showModal(statusCode, response.data.message)
            } else if (statusCode === '422') {
                this.modalResponseErrors = response.data.errors
            } else {
                this.$refs.feedbackModal.showModal(statusCode, response.data.message)
            }

            this.deleteLoading = false
        },
        async handleRemoveRegional(id) {
            const index = this.fileModal.point_of_sale_regionals.findIndex(find => find.id === id);

            if (index > -1) {
                this.fileModal.point_of_sale_regionals.splice(index, 1);
            }
        },
        async handleRemoveTeam(id) {
            const index = this.fileModal.teams.findIndex(find => find.id === id);

            if (index > -1) {
                this.fileModal.teams.splice(index, 1);
            }
        },
        async handleRemoveUser(id) {
            const index = this.fileModal.users.findIndex(find => find.id === id);

            if (index > -1) {
                this.fileModal.users.splice(index, 1);
            }
        },
        async handleRemoveRole(id) {
            const index = this.fileModal.roles.findIndex(find => find.id === id);

            if (index > -1) {
                this.fileModal.roles.splice(index, 1);
            }
        },
    },
}
</script>
