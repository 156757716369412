<script>
import fileTypes from '@src/mixins/fileTypes'
import vuetableFormatter from '@src/mixins/vuetableFormatters'

const i18nCommon = 'COMMON'

export default {
    mixins: [fileTypes, vuetableFormatter],
    data() {
        return {
            fileFields: [
                {
                    name: 'name',
                    title: this.getI18n(i18nCommon, 'name'),
                    sortField: 'name',
                },
                {
                    name: 'name_menu',
                    title: this.getI18n(i18nCommon, 'menu'),
                    sortField: 'name_menu',
                },
                {
                    name: 'type',
                    title: this.getI18n(i18nCommon, 'type'),
                    sortField: 'type',
                    formatter: this.fileTypeToText,
                },
                {
                    name: 'start_at',
                    title: this.getI18n(i18nCommon, 'start_date'),
                    sortField: 'start_at',
                    formatter: this.formatDate,
                },
                {
                    name: 'end_at',
                    title: this.getI18n(i18nCommon, 'end_date'),
                    sortField: 'end_at',
                    formatter: this.formatDate,
                },
                {
                    name: 'point_of_sale_regionals',
                    title: this.getI18nModified({
                        prefix: 'POS',
                        suffix: 'TITLES.regional',
                        modifier: 2,
                    }),
                },
                {
                    name: 'teams',
                    title: this.getI18nModified({
                        prefix: 'TEAMS',
                        suffix: 'TITLES.team',
                        modifier: 2,
                    }),
                },
                {
                    name: 'users',
                    title: this.getI18nModified({
                        prefix: 'USERS',
                        suffix: 'TITLES.user',
                        modifier: 2,
                    }),
                },
                {
                    name: 'roles',
                    title: this.getI18nModified({
                        prefix: 'SECURITY',
                        suffix: 'TITLES.role',
                        modifier: 2,
                    }),
                },
                {
                    name: 'file',
                    title: this.getI18n(i18nCommon, 'file'),
                },
                {
                    name: 'updated_at',
                    title: this.getI18n(i18nCommon, 'updated_at'),
                    sortField: 'updated_at',
                },
                {
                    name: 'actions',
                    title: '',
                },
            ],
        }
    },
}
</script>
