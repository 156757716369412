<script>

export default {
    data() {
        return {
            userChildrenActionFields: [
                {
                    key: 'name',
                    label: this.getI18n('COMMON.user_name'),
                    sortable: true,
                },
                {
                    key: 'email',
                    label: this.getI18n('COMMON.email'),
                    sortable: true,
                },
                {
                    key: 'actions',
                    label: '',
                },
            ],
        }
    },
}
</script>
